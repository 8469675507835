import React from "react";
import { AuthContext } from "./context";
import { ClientObject, ReferantObject } from "../../types/models";
import { useBasicDataHook, useStateBasicCryptSave } from "../basicdata/hooks";
import { useAuthHook } from "./hooks";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

const AuthProvider = ({ children = <div /> }) => {
  const navigate = useNaviLoadBoth;
  const { setLocalCountryFromCode } = useBasicDataHook();
  /// Variables
  const [client, setClient] = useStateBasicCryptSave(
    "client_data",
    ClientObject
  );
  const [referant, setReferant] = useStateBasicCryptSave(
    "referant_data",
    ReferantObject
  );
  function setConnectionData(data: any) {
    if (client.client_id !== "") {
      setLocalCountryFromCode(client.client_country);
    }
    setReferant(data.referant);
    setClient(data.client);
  }
  function logOut() {
    setClient(ClientObject);
    setReferant(ReferantObject);
    navigate("/");
  }
  return (
    <AuthContext.Provider
      value={{
        setConnectionData,
        connected: client.client_id !== "",
        client,referant,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthRequire = ({ children = <div /> }) => {
  const { connected } = useAuthHook();
  const navigate = useNaviLoadBoth;
  if (connected) {
    return children;
  } else {
    navigate("/signin");
    return <div />;
  }
};

export default AuthProvider;
