import React from "react";
import Header from "./header";
import BlocsRow from "./bocsrow";
import TableDataAutoList from "../../../../components/array";
import { useAuthDataHook } from "../../../../contexts/authData/hooks";
import { useBasicDataHook } from "../../../../contexts/basicdata/hooks";

const DashboardHome = () => {
  const { t } = useBasicDataHook();
  const { projets, etudes } = useAuthDataHook();
  const columnsRecenteReservations = [
    {
      label: t("project"),
      slug: "projetName",
    },
    {
      label: t("studies"),
      slug: "etudes",
    },
  ];
  return (
    <div className="flex flex-1 flex-col gap-5">
      <Header />
      <BlocsRow />
      <div className="flex flex-col mt-3 gap-3">
        <span className="flex text-[17px] font-normal">
        {t("summary")}
        </span>
        <TableDataAutoList
          columns={columnsRecenteReservations}
          rows={projets.map((projet) => ({
            projetName: projet.projet_title,
            etudes: etudes
              .filter((e) => e.projet_id === projet.projet_id)
              .length.toString(),
          }))}
        />
      </div>
    </div>
  );
};

export default DashboardHome;
