import axios from "axios";

// export const onlineAccountsApisUrl = "https://apicoinchicdumoment.onrender.com";
export const onlineAccountsApisUrl = "https://apiinsight.vercel.app";
export const localAccountsApisUrl = window.location.hostname.includes("local")
  ? "http://localhost:5050"
  : window.location.hostname.includes("192.168")
  ? "http://" + window.location.hostname + ":5050"
  : onlineAccountsApisUrl;

export const usedBaseUrl = localAccountsApisUrl;

// export const imageBaseUrl = localAccountsApisUrl+"/image/lms";

export const request = axios.create({
  baseURL: usedBaseUrl + "/v1",
  params: {
    // key: process.env.REACT_APP_API_KEY,
  },
});
