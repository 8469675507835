import forge from "node-forge";

export function encryptKey(key = {}) {
  const publicKey = forge.pki.publicKeyFromPem(
    process.env.REACT_APP_SERVER_PUBLIC_KEY ?? ""
  );
  // Crypter une chaîne avec la clé publique
  const plaintext = JSON.stringify({ data: key });
  const ciphertext = forge.util.encode64(publicKey.encrypt(plaintext));
  return ciphertext;
}

export function decryptKey(encriptedKey: string) {
  const privateKey = forge.pki.privateKeyFromPem(
    process.env.REACT_APP_CLIENT_PRIVATE_KEY ?? ""
  );
  // Décrypter la chaîne avec la clé privée
  const decrypted = privateKey.decrypt(forge.util.decode64(encriptedKey));
  return JSON.parse(decrypted).data;
}
