import React from "react";
import { useBasicDataHook } from "../../contexts/basicdata/hooks";

const Footer = () => {
  const { t } = useBasicDataHook();
  return (
    <div className="footerCompoent flex flex-row justify-between w-full py-[6px] px-10 bg-white border-t border-black/20">
      <span className="flex text-primary text-[10px]">
        © 2022 Insight africa, {t("rigthResrved")}
      </span>
      <div className="flex text-[10px] gap-1">
        <span className="flex"> {t("buildBy")}</span>
        <a
          href="http://www.awanze.com"
          target="_blank"
          className="flex text-primary font-semibold"
          rel="noreferrer"
        >
          Awanze
        </a>
      </div>
    </div>
  );
};

export default Footer;
