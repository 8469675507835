import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SidebarItem = ({
  item = {
    url: "",
    slug: "",
    isDash: false,
    title: "",
    img: ({
      color,
      ...args
    }: {
      [x: string]: any;
      color?: string | undefined;
    }) => <div />,
  },
}) => {
  const [hover, setHover] = React.useState(false);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        navigate(item.url + search);
      }}
      id={item.url + "Id"}
      className={`flex w-full gap-3 transition-all py-[15px] px-4 pl-10 cursor-pointer select-none items-center ${
        (item.isDash && pathname.split("/")[2] === undefined
          ? pathname.split("/")[1]
          : pathname.split("/")[2]) === item.slug
          ? "bg-primary hover:bg-primary"
          : " hover:bg-hover/10"
      }`}
    >
      <div className="flex">
        {item.img({
          color:
            (item.isDash && pathname.split("/")[2] === undefined
              ? pathname.split("/")[1]
              : pathname.split("/")[2]) === item.slug
              ? "white"
              : hover
              ? "black"
              : "black",
        })}
      </div>
      <span
        className={`flex text-[17px] font-medium transition-all ${
          (item.isDash && pathname.split("/")[2] === undefined
            ? pathname.split("/")[1]
            : pathname.split("/")[2]) === item.slug
            ? " text-white"
            : ""
        }`}
      >
        {item.title}
      </span>
    </div>
  );
};

export default SidebarItem;
