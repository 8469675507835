import React from "react";
import BlocItem from "./bloc";
import { useAuthDataHook } from "../../../../../contexts/authData/hooks";
import { useBasicDataHook } from "../../../../../contexts/basicdata/hooks";

const BlocsRow = () => {
  const { t } = useBasicDataHook();
  const { projets, etudes } = useAuthDataHook();
  return (
    <div className="grid w-fit flex-row sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-5">
      <BlocItem title={t("projects")} number={projets.length.toString()} />
      <BlocItem  title={t("studies")} number={etudes.length.toString()}/>
    </div>
  );
};

export default BlocsRow;
