import React from "react";
import { usePopUpHook } from "../../functions/popuphook";
import { useBasicDataHook } from "../../contexts/basicdata/hooks"
import Icon from "../../assets/icon.svg"

const SwitchLangButton = () => {
  const { langs, setLang, selectedLang } = useBasicDataHook();
  const { refInput, refPopUp, open, switchMenu } = usePopUpHook<
    HTMLButtonElement,
    HTMLDivElement
  >();
  return (
    <div className="flex flex-col select-none">
      <button ref={refInput} className="flex text-[#fff] font-medium gap-1 sm:hidden" onClick={switchMenu}>
        <img className="flex" alt="" src={Icon} />
        <span className="flex">{selectedLang.slug}</span>
      </button>
      <div
        ref={refPopUp}
        className="flex flex-col fixed select-none overflow-y-auto rounded-lg bg-white border py-1 shadow-xl shadow-black/5 text-[16px]"
        style={{
          top: 65,
          minHeight: 30,
          minWidth: 60,
          visibility: open ? "visible" : "hidden",
        }}
      >
        {langs.map(function (lang, index) {
          return (
            <button
              className="flex w-full py-[6px] hover:bg-slate-100/50 px-4"
              onClick={function () {
                setLang(lang);
                switchMenu();
                window.location.reload()
              }}
              key={index}
            >
              <span
                className={`flex text-start ${
                  lang.slug === selectedLang.slug
                    ? "text-primary font-semibold"
                    : "text-black font-light"
                }`}
              >
                {lang.lang}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SwitchLangButton;
