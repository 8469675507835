import React from "react";
import { useAuthHook } from "../../../../contexts/auth/hooks";
import { useBasicDataHook } from "../../../../contexts/basicdata/hooks";

const Header = () => {
  const { t } = useBasicDataHook();
  const { client } = useAuthHook();
  return (
    <div className="flex flex-row items-center justify-between">
      <span className="flex font-medium text-[18px]">{t("welcomeDahboard")}</span>
      <div className="flex flex-row gap-5 items-center">
        <span className="flex font-semibold text-[18px]">
          {client.client_name.toUpperCase()}
        </span>
      </div>
    </div>
  );
};

export default Header;
