import React from "react";
import { useContext } from "react";
import { BasicDataContext } from "./context";
import { encryptDataAesGcmLocal } from "../../configs/gcm-encrypt";
import CryptoJS from "crypto-js";
import { decryptDataAesGcm } from "../../configs/gcm-decrypt";
import forge from "node-forge";
import { generateUniqueName } from "../../functions/generateUniqueName";

export function useBasicDataHook() {
  return useContext(BasicDataContext);
}

export function useStateBasicSave<T>(
  name: string,
  defaultValue: T
): [T, React.Dispatch<T>] {
  const retrieved = JSON.parse(localStorage.getItem(name) ?? '{"saved":null}')
    .saved as T;
  const [state, setState] = React.useState(retrieved ?? defaultValue);
  React.useEffect(() => {
    localStorage.setItem(
      name,
      JSON.stringify({ saved: state ?? defaultValue })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return [state, setState];
}

export function useStateBasicCryptSave<T>(
  nm: string,
  defaultValue: T
): [T, React.Dispatch<T>] {
  const name = generateUniqueName(nm);
  const retrieved = JSON.parse(DecryptLocalData(localStorage.getItem(name)))
    .saved as T;
  const [state, setState] = React.useState(retrieved ?? defaultValue);
  React.useEffect(() => {
    localStorage.setItem(
      name,
      EncryptLocalData(name, JSON.stringify({ saved: state ?? defaultValue }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return [state, setState];
}

function EncryptLocalData(name: string, data: any) {
  let encrypted = encryptDataAesGcmLocal(
    data,
    (process.env.REACT_APP_LOCALDATA_KEY ?? "") + name
  );
  const crypted = CryptoJS.AES.encrypt(
    JSON.stringify(encrypted),
    process.env.REACT_APP_LOCALDATA_KEY ?? ""
  ).toString();
  return forge.util.encode64(crypted);
}

function DecryptLocalData(data: any) {
  if (data) {
    try {
      var bytes = CryptoJS.AES.decrypt(
        forge.util.decode64(data),
        process.env.REACT_APP_LOCALDATA_KEY ?? ""
      ).toString(CryptoJS.enc.Utf8);

      var decryptedData = JSON.parse(bytes);
      let decryption = decryptDataAesGcm(
        decryptedData.encryptionKey,
        decryptedData.data
      );
      return decryption;
    } catch (error) {
      return '{"saved":null}';
    }
  } else {
    return '{"saved":null}';
  }
}
