import React from "react";
import TextInput from "../../../../components/inputs/input";
import ProjectsArray from "./projectsArray";
import { useBasicDataHook } from "../../../../contexts/basicdata/hooks";

const ProjetsContent = () => {
  const { t } = useBasicDataHook();
  const [search, setSearch] = React.useState("");
  return (
    <div className="flex flex-1 flex-col gap-10">
      <div className="flex h-fit flex-row justify-between items-center">
        <span className="flex text-[22px] font-medium">{t("listProject")}</span>
      </div>
      <div className="flex flex-col h-[40px] w-[40%]">
        <TextInput
          placeholder={t("searchProject")}
          onChange={(s) => {
            setSearch(s);
          }}
        />
      </div>
      <ProjectsArray search={search} />
    </div>
  );
};

export default ProjetsContent;
