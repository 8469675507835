import { useNavigate } from "react-router-dom";
import { useAuthDataHook } from "../../../../contexts/authData/hooks";
import { ProjetObject } from "../../../../types/models";
import React from "react";
import { useBasicDataHook } from "../../../../contexts/basicdata/hooks";

const ProjectCard = ({ projet = ProjetObject }) => {
  const { t } = useBasicDataHook();
  const { etudes } = useAuthDataHook();
  const navigate = useNavigate();
  function open() {
    navigate(projet.projet_id);
  }
  return (
    <div className="flex flex-col col-span-1 rounded-xl shadow bg-white relative">
      <div className="flex flex-col p-4 pb-1 gap-2">
        <span className="flex flex-1 font-medium text-[22px]">
          {projet.projet_title}
        </span>
        <span className="flex flex-1 font-medium text-[15px] text-primary/80">
          {etudes.filter((e) => e.projet_id === projet.projet_id).length} etudes
        </span>
        <span className="flex flex-1 font-medium text-[10px] text-black/50">
          Creation: {projet.projet_created_date.split("T")[0]}
        </span>
      </div>
      <div className="flex p-3 pt-2">
        <button
          onClick={open}
          className="flex transition-all flex-1 rounded-md bg-primary hover:bg-hover active:bg-active items-center justify-center py-2"
        >
          <span className="flex text-white font-medium">{t("openProject")}</span>
        </button>
      </div>
    </div>
  );
};
//LoadingCircle
export default ProjectCard;
