import TextInput from "../../components/inputs/input";
import PasswordInput from "../../components/inputs/password";
import { useBasicDataHook } from "../../contexts/basicdata/hooks";
import { useSignInHook } from "../../contexts/signin/hooks";

const SignIn = () => {
  const { t } = useBasicDataHook();
  const { errorState, isLoading, onValueChange, submitForm } = useSignInHook();
  return (
    <div className="flex flex-1 justify-center items-start">
      <form
        className="flex flex-col w-[350px] gap-4 py-10"
        onSubmit={(e) => {
          e.preventDefault();
          !isLoading && submitForm();
          if (isLoading) {
            alert(
              "Veuillez patienter pendant que nous établissons la connexion. Cette opération devrait prendre un court instant."
            );
          }
        }}
      >
        <span className="flex flex-col text-[20px] text-black font-semibold text-center">
          <span>{t("signIn.title")}</span>
          <span>{t("signIn.subtitle")}</span>
        </span>
        <TextInput
          label="Email"
          errorState={errorState.login}
          onChange={onValueChange("login")}
        />
        <PasswordInput
          label={t("signIn.password")}
          errorState={errorState.password}
          onChange={onValueChange("password")}
        />
        {!!errorState.generalMsg && (
          <div className="flex w-full">
            <span className="flex text-[15px] font-medium text-red-600">
              {errorState.generalMsg}
            </span>
          </div>
        )}
        <a href="/mot-de-passe-oublie" className="group flex">
          <span className="flex text-primary group-hover:text-hover font-medium text-[13px]">
            {t("signIn.forgotPassword")}
          </span>
        </a>
        <button
          className={`flex ${
            !isLoading ? "bg-primary hover:bg-hover" : "bg-hover"
          }  items-center justify-center py-[16px]`}
        >
          <span className="flex text-white font-semibold text-[18px]">
            {isLoading ? t("signIn.connecting") + "...." : t("signIn.connect")}
          </span>
        </button>
        <span className="flex flex-col text-[13px] text-gray text-center">
          <span>{t("signIn.termsAndPrivacy")}</span>
        </span>
      </form>
    </div>
  );
};

export default SignIn;
