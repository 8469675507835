import forge from "node-forge";
import {
  generateEncryptionKeyFromPassword,
  generateEncryptionKeyFromPasswordLocal,
} from "./generateKeyFromPassword";

// Fonction pour encrypter une chaîne de caractères avec une clé de chiffrement unique et un mode de chiffrement authentifié
function encryptStringWithKeyAndAuthMode(message: string, key: string) {
  // Convertir la chaîne de caractères en un objet tampon utilisable par Node-Forge
  const messageBuffer = forge.util.createBuffer(message, "utf8");

  // Générer un vecteur d'initialisation aléatoire de 16 octets
  const iv = forge.random.getBytesSync(
    parseInt(process.env.REACT_APP_getBytesSync ?? "64")
  );

  // Créer un chiffreur AES en mode GCM (chiffrement authentifié)
  const cipher = forge.cipher.createCipher("AES-GCM", key);

  // Encrypter le message
  cipher.start({ iv });
  cipher.update(messageBuffer);
  cipher.finish();
  const encrypted = cipher.output.getBytes();

  // Retourner les données encryptées et les paramètres utilisés pour encrypter
  return { encrypted, iv, tag: JSON.stringify(cipher.mode.tag) };
}

export function toHex(str: string) {
  // Convertir la chaîne de caractères en un objet tampon utilisable par Node-Forge
  const messageBuffer = forge.util.createBuffer(str, "utf8");
  // Convertir le tampon en une représentation hexadécimale
  const hex = forge.util.bytesToHex(messageBuffer.bytes());
  return hex;
}

export function encryptDataAesGcm(data: any = "test") {
  const encryptionKey = generateEncryptionKeyFromPassword();
  const { encrypted, iv, tag } = encryptStringWithKeyAndAuthMode(
    JSON.stringify({ data }),
    encryptionKey.key
  );
  return {
    encryptionKey: {
      key: forge.util.encode64(encryptionKey.key),
      iv: forge.util.encode64(iv),
      tag: forge.util.encode64(tag),
    },
    data: forge.util.encode64(encrypted),
  };
}

export function encryptDataAesGcmLocal(data: any = "test", password: string) {
  const encryptionKey = generateEncryptionKeyFromPasswordLocal(password);
  const { encrypted, iv, tag } = encryptStringWithKeyAndAuthMode(
    JSON.stringify({ data }),
    encryptionKey.key
  );
  return {
    encryptionKey: {
      key: forge.util.encode64(encryptionKey.key),
      iv: forge.util.encode64(iv),
      tag: forge.util.encode64(tag),
    },
    data: forge.util.encode64(encrypted),
  };
}
