import React from "react";
import SwitchLangButton from "./switchLangButton";
import help from "../../assets/help.svg";
import notif from "../../assets/notif.svg";
import { IconButton } from "@mui/material";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
import { useModalHook } from "../../functions/useModalHook";
import { useModalsHook } from "../../contexts/modals/hooks";
import { useAuthHook } from "../../contexts/auth/hooks";
import { useBasicDataHook } from "../../contexts/basicdata/hooks";

const ConnectedHeader = () => {
  const { t } = useBasicDataHook();
  const { logOut } = useAuthHook();
  const { setModal } = useModalHook();
  const { setData } = useModalsHook();
  return (
    <div className="flex flex-row items-center gap-[27px]">
      <SwitchLangButton />
      <button className="flex">
        <img className="flex" alt="" src={help} />
      </button>
      <button className="flex">
        <img className="flex" alt="" src={notif} />
      </button>

      <IconButton
        edge="end"
        color="default"
        style={{ color: "white" }}
        aria-label="logout"
        size="small"
        onClick={function () {
          setData({
            confirmationButtonText: t("logout"),
            confirmationText: t("sureLogOut"),
            confirm: function () {
              logOut();
            },
          });
          setModal("confirm_action");
        }}
      >
        <PowerSettingsNew />
      </IconButton>
    </div>
  );
};

export default ConnectedHeader;
