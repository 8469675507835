import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./loc/loc.en.json";
import fr from "./loc/loc.fr.json";

const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "fr",
});

export default i18next;
