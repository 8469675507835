import forge from "node-forge";

function key(password: any, salt: any, messageDigest: any) {
  return forge.pkcs5.pbkdf2(
    password,
    salt,
    parseInt(process.env.REACT_APP_iterations ?? "3000"),
    parseInt(process.env.REACT_APP_keySize ?? "128"),
    messageDigest
  );
}
// Fonction pour générer une clé de chiffrement unique à partir d'un mot de passe
export function generateEncryptionKeyFromPassword() {
  const password = forge.random.getBytesSync(
    parseInt(process.env.REACT_APP_VALIDATION_KEY_LENGTH ?? "32")
  );
  const salt = forge.random.getBytesSync(
    parseInt(process.env.REACT_APP_getBytesSync ?? "64")
  );
  return {
    key: key(password, salt, process.env.REACT_APP_messageDigest),
    salt,
  };
}

export function generateEncryptionKeyFromPasswordLocal(password: string) {
  const salt = forge.random.getBytesSync(
    parseInt(process.env.REACT_APP_getBytesSync ?? "64")
  );
  return {
    key: key(password, salt, process.env.REACT_APP_messageDigest),
    salt,
  };
}
