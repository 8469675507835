import { Navigate, Route, Routes } from "react-router-dom";
import { Forloadandnavigate } from "./functions/useNaviLoadBoth";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import SignIn from "./pages/signin";
import SignInProvider from "./contexts/signin/provider";
import EmailValidation from "./pages/emailValidation";
import CompteDesactiver from "./pages/compteDesactiver";
import ForgotPassword from "./pages/forgotPassword";
import EmailVerificationProvider from "./contexts/emailValidation/provider";
import { AuthRequire } from "./contexts/auth/provider";
import { useAuthDataHook } from "./contexts/authData/hooks";
import Dashboard from "./pages/dashboard";
import ProjetProvider from "./contexts/projet/provider";
import ProjetContent from "./pages/dashboard/contents/projet";

function App() {
  const { sidebarItems } = useAuthDataHook();
  return (
    <div className="flex flex-col min-h-screen h-full w-full font-inter scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-300 hover:scrollbar-thumb-gray-700 active:scrollbar-thumb-gray-700">
      <div className="flex flex-1 pt-[60px] bg-gradient-to-b from-[#f5f5f5] to-white bg-cover bg-primary-contrast1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/signin"
            element={
              <SignInProvider>
                <SignIn />
              </SignInProvider>
            }
          />
          {/* <Route path="/signup" element={<SignUp />} /> */}
          <Route
            path="/email-validation"
            element={
              <EmailVerificationProvider>
                <EmailValidation />
              </EmailVerificationProvider>
            }
          />
          <Route path="/compte-desactiver" element={<CompteDesactiver />} />
          <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
          <Route
            path="/dashboard"
            element={
              <AuthRequire>
                <Dashboard />
              </AuthRequire>
            }
          >
            {sidebarItems.map(({ url, content }, index) => {
              return (
                <Route
                  index={index === 0}
                  key={index}
                  path={url}
                  element={content}
                />
              );
            })}
            <Route path="projets">
              <Route
                path=":projectId"
                element={
                  <ProjetProvider>
                    <ProjetContent />
                  </ProjetProvider>
                }
              >
                <Route path="etude/:etudeId" element={<div />} />
              </Route>
            </Route>
            <Route path="**/*" element={<Navigate to={""} />} />
          </Route>
        </Routes>
      </div>
      <Header />
      <Forloadandnavigate />
      <Footer />
    </div>
  );
}

export default App;
