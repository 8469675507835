import React from "react";
import { AuthDataContext } from "./context";
import { useAuthHook } from "../auth/hooks";
import DashboardIcon from "../../components/icons/dashboardIcon";
import ReservationIcon from "../../components/icons/reservationIcon";
import ParamtreIcon from "../../components/icons/parametreIcon";
import DashboardHome from "../../pages/dashboard/contents/home";
import ParametreContent from "../../pages/dashboard/contents/parametre";
import ProjetsContent from "../../pages/dashboard/contents/projects";
import { useBasicDataHook, useStateBasicCryptSave } from "../basicdata/hooks";
import { EtudeObject, ProjetObject } from "../../types/models";
import { getEtudes, getProjets } from "./functions";
import PageDataLoading from "../../pages/pageDataLoading";

const AuthDataProvider = ({ children = <div /> }) => {
  const { t } = useBasicDataHook();
  const { connected, client } = useAuthHook();
  const [projets, setProjets] = useStateBasicCryptSave(
    "projets",
    Array<typeof ProjetObject>()
  );
  const [etudes, setEtudes] = useStateBasicCryptSave(
    "etudes",
    Array<typeof EtudeObject>()
  );
  const [pro, setPro] = React.useState(false);
  const [etu, setEtu] = React.useState(false);
  const sidebarItems = [
    {
      img: DashboardIcon,
      title: "Dashboard",
      url: "",
      slug: "dashboard",
      content: <DashboardHome />,
    },
    {
      img: ReservationIcon,
      title: t("projects"),
      url: "projets",
      slug: "projets",
      content: <ProjetsContent />,
    },
    {
      img: ParamtreIcon,
      title: t("settings"),
      url: "parametre",
      slug: "parametre",
      content: <ParametreContent />,
    },
  ];
  function refreshProjets() {
    getProjets(client.client_id, function (data) {
      setProjets([]);
      setProjets(data.data);
      setPro(true);
    });
  }
  function refreshEtudes() {
    getEtudes(client.client_id, function (data) {
      setEtudes([]);
      setEtudes(data.data);
      setEtu(true);
    });
  }
  function refreshClientData() {}
  /// UseEffects
  React.useEffect(() => {
    refreshProjets();
    refreshEtudes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AuthDataContext.Provider
      value={{
        sidebarItems,
        projets,
        etudes,
        client,
        refreshProjets,
        refreshEtudes,
        refreshClientData,
      }}
    >
      {connected ? pro && etu ? children : <PageDataLoading /> : children}
    </AuthDataContext.Provider>
  );
};
export default AuthDataProvider;
