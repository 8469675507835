import React from "react";
import { useAuthDataHook } from "../../contexts/authData/hooks";
import SidebarItem from "./sidebaritem";

const SideBar = () => {
  const { sidebarItems } = useAuthDataHook();
  return (
    <div className="flex gap-[1px] w-[230px] border-r pr-[1px] pt-[1px] pl-[1px] pb-[30px] flex-col bg-[#CECECE] h-full overflow-auto scrollbar-track-rounded-xxl scrollbar-thumb-rounded-xxl scrollbar-thumb-gray-500/20 scrollbar-track-gray-100/0 scrollbar-thin hover:scrollbar-thumb-gray-500/50 active:scrollbar-thumb-gray-500/60">
      <div className="flex flex-col">
        {sidebarItems.map((item, index) => {
          return (
            <SidebarItem
              key={index}
              {...{ item: { ...item, isDash: item.slug === "dashboard" } }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;
