import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { getPropValue } from "../../functions/getPropValue";
import { IconButton, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Visibility } from "@mui/icons-material";
import { useBasicDataHook } from "../../contexts/basicdata/hooks";

interface Column {
  align?: "center" | "left" | "right" | "inherit" | "justify" | undefined;
  minWidth?: string;
  label: string;
  slug?: string;
}

interface Row {
  [key: string]: any;
}

interface Props {
  columns: Column[];
  rows: Row[];
  maxWidth?: number | string;
  maxHeight?: number | string;
  viewvable?: boolean;
  viewHead?: string;
  viewIcon?: any;
  onView?: (data: any) => void;
  editable?: boolean;
  onEdit?: (data: any) => void;
  removable?: boolean;
  onRemove?: (data: any) => void;
  toggleableState?: boolean;
  onToogleState?: (data: any, value: boolean) => void;
}

function TableDataAutoList({
  columns = [],
  rows = [],
  maxHeight = "auto",
  maxWidth = "100%",
  viewvable = false,
  viewHead = "Afficher",
  viewIcon = null,
  onView,
  editable = false,
  onEdit,
  removable = false,
  onRemove,
  toggleableState = false,
  onToogleState,
}: Props) {
  const { t } = useBasicDataHook();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      sx={{ width: "100%", maxWidth: maxWidth, overflow: "hidden", zIndex: 0 }}
      elevation={0}
    >
      <TableContainer sx={{ maxHeight: maxHeight, zIndex: 0 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {viewvable && (
                <TableCell
                  sx={{ width: 25 }}
                  align={"center"}
                  style={{ fontWeight: "600" }}
                >
                  {viewHead}
                </TableCell>
              )}
              {columns
                .filter((e) => !["state"].includes(e.slug ?? ""))
                .map((column, index) => (
                  <TableCell
                    key={column.slug}
                    align={"left"}
                    sx={{ width: "fit" }}
                    style={{ minWidth: column.minWidth, fontWeight: "700" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              {toggleableState &&
                !!columns.filter((e) =>
                  ["state"].includes(e.slug ?? "")
                )[0] && (
                  <TableCell
                    align={"center"}
                    sx={{ width: 45 }}
                    style={{ fontWeight: "600" }}
                  >
                    Etat
                  </TableCell>
                )}
              {editable && (
                <TableCell
                  align={"center"}
                  sx={{ width: 45 }}
                  style={{ fontWeight: "600" }}
                >
                  Modifier
                </TableCell>
              )}
              {removable && (
                <TableCell
                  align={"center"}
                  sx={{ width: 45 }}
                  style={{ fontWeight: "600" }}
                >
                  Supprimer
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length !== 0 ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {viewvable && (
                        <TableCell
                          sx={{ width: 25 }}
                          align={"center"}
                          style={{ fontWeight: "600" }}
                        >
                          <IconButton
                            edge="end"
                            color="default"
                            onClick={() => {
                              !!onView && onView(row);
                            }}
                            aria-label="edit"
                            size="small"
                          >
                            {viewIcon ?? <Visibility />}
                          </IconButton>
                        </TableCell>
                      )}
                      {columns.map((column, index2) => {
                        const value = getPropValue(row, column.slug);
                        if (column.slug === "state") {
                          return (
                            toggleableState && (
                              <TableCell key={index2} align={"center"}>
                                <Switch
                                  size="small"
                                  checked={value}
                                  sx={{ width: 45 }}
                                  onChange={(e, b) => {
                                    !!onToogleState && onToogleState(row, b);
                                  }}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              </TableCell>
                            )
                          );
                        }
                        return (
                          <TableCell key={index2} align={column.align}>
                            {(value ?? "").length > 10
                              ? value.slice(0, 15) + "..."
                              : value}
                          </TableCell>
                        );
                      })}

                      {editable && (
                        <TableCell sx={{ width: 45 }} align={"center"}>
                          <IconButton
                            edge="end"
                            color="default"
                            onClick={() => {
                              !!onEdit && onEdit(row);
                            }}
                            aria-label="edit"
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      )}
                      {removable && (
                        <TableCell sx={{ width: 45 }} align={"center"}>
                          <IconButton
                            edge="end"
                            color="default"
                            onClick={() => {
                              !!onRemove && onRemove(row);
                            }}
                            aria-label="delete"
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell
                  align={"center"}
                  // sx={{ width: 45 }}
                  style={{ fontWeight: "500" }}
                >
                  {t("noData")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default TableDataAutoList;
