import { createContext } from "react";
import { ClientObject, EtudeObject, ProjetObject } from "../../types/models";

export const AuthDataContext = createContext({
  sidebarItems: Array<{
    img: ({
      color,
      ...args
    }: {
      [x: string]: any;
      color?: string | undefined;
    }) => JSX.Element;
    title: string;
    url: string;
    slug: string;
    content: JSX.Element;
    subroute?: Array<{
      img: ({
        color,
        ...args
      }: {
        [x: string]: any;
        color?: string | undefined;
      }) => JSX.Element;
      title: string;
      url: string;
      slug: string;
      content: JSX.Element;
    }>;
  }>(),
  client: ClientObject,
  projets: Array<typeof ProjetObject>(),
  etudes: Array<typeof EtudeObject>(),
  refreshClientData: () => {},
  refreshProjets: () => {},
  refreshEtudes: () => {},
});
