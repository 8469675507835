import React from "react";
import { SignInContext } from "./context";
import { sendSigninData } from "./functions";
import { isEmail } from "../../constants";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { getPropValue } from "../../functions/getPropValue";
import { useAuthHook } from "../auth/hooks";
import { useBasicDataHook } from "../basicdata/hooks";

function SignInProvider({ children = <div /> }) {
  const { t } = useBasicDataHook();
  const { setConnectionData, connected } = useAuthHook();
  const navigate = useNaviLoadBoth;
  const [formData, setFormData] = React.useState({
    login: "",
    password: "",
  });
  const [errorState, setErrorState] = React.useState({
    login: { state: false, msg: "" },
    password: { state: false, msg: "" },
    generalMsg: "",
  });
  const [isLoading, setisLoading] = React.useState(false);
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
    };
  }
  function checkFormData() {
    let errorOccured = false;
    // email_pro
    if (formData.login.length === 0 || !isEmail(formData.login)) {
      errorOccured = true;
      let msg = t("emailIncorrect");
      setErrorState((e) => ({ ...e, login: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, login: { state: false, msg: "" } }));
    }
    // password
    if (formData.password.length === 0) {
      errorOccured = true;
      let msg = t("emptyPassword");
      setErrorState((e) => ({ ...e, password: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, password: { state: false, msg: "" } }));
    }
    return errorOccured;
  }
  function submitForm() {
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    // console.log(formData);
    setisLoading(true);
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    sendSigninData(
      {
        login: formData.login,
        password: formData.password,
      },
      function (reponse) {
        setisLoading(false);
        if (reponse.success) {
          setConnectionData(reponse.data);
        } else {
          setTimeout(() => {
            setErrorState((e) => ({
              ...e,
              generalMsg: "",
            }));
          }, 10000);
          setErrorState((e) => ({
            ...e,
            login: { state: true, msg: "" },
            password: { state: true, msg: "" },
            generalMsg: reponse.msg,
          }));
          if (reponse?.redirection) {
            navigate(reponse.redirection);
          }
        }
      }
    );
  }
  React.useEffect(() => {
    if (connected) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return (
    <SignInContext.Provider
      value={{ formData, isLoading, errorState, onValueChange, submitForm }}
    >
      {children}
    </SignInContext.Provider>
  );
}

export default SignInProvider;
