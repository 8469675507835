import { useState } from "react";
import { useEmailValidationHook } from "../../contexts/emailValidation/hooks";
import { primaryColor } from "../../constants";
const EmailValidation = () => {
  const {
    isLoading,
    client_email,
    confirmationError,
    confirmCode,
    reinitialiseError,
    isConfirmationLoading,
  } = useEmailValidationHook();
  const [code, setCode] = useState(["", "", "", ""]);
  const handleInput = (index: number, event: any) => {
    reinitialiseError();
    const value = event.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      setCode([...code.slice(0, index), value, ...code.slice(index + 1)]);
      if (index < 3 && value.length === 1) {
        event.target.nextSibling.focus();
      }
      if (index > 0 && index < 4 && value.length === 0) {
        event.target.previousSibling.focus();
      }
    }
  };

  const handlePaste = (event: any) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData("Text");
    const pastedCode = clipboardData.slice(0, 4).split("");
    const newCode = [...code];
    pastedCode.forEach((value: string, index: number) => {
      if (/^\d*$/.test(value) && value.length === 1) {
        newCode[index] = value;
      }
    });
    setCode(newCode);
  };
  return (
    <div className="flex flex-1 justify-center items-start">
      <div className="flex flex-col w-[350px] bg-white mt-10 p-8 gap-5 rounded-lg">
        <span className="flex text-[22px] font-medium">
          Verification de l'email
        </span>
        {!isLoading ? (
          <>
            <span className="flex text-[16px] font-medium flex-col gap-1">
              <span className="flex ">
                Veuillez saisir le code recu sur votre boite email:
              </span>
              <span className="flex text-primary">{client_email}</span>
            </span>
            <div className="flex w-full flex-row justify-center gap-4">
              {code.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength={1}
                  value={value}
                  className={`flex w-[20%] h-[60px] bg-gainsboro/50 rounded-md text-[30px] text-center ${
                    confirmationError
                      ? "ring-red-600 ring-2 outline-red-600 text-red-600"
                      : "ring-primary ring-2 outline-hover text-black"
                  }`}
                  onChange={(event) => handleInput(index, event)}
                  onPaste={handlePaste}
                />
              ))}
            </div>
            <button
              className="flex w-full cursor-pointer bg-primary hover:bg-hover justify-center items-center p-3 rounded-md text-white"
              disabled={code.some((value) => value === "")}
              onClick={function () {
                !isConfirmationLoading && confirmCode(code.join(""));
              }}
            >
              <span className="flex font-medium text-[16px]">
                {isConfirmationLoading ? "Verification..." : "Valider le code"}
              </span>
            </button>
          </>
        ) : (
          <div className="flex flex-row gap-3 items-center">
            <svg
              className="animate-spin h-6 w-6 text-black/50"
              xmlns="http://www.w3.org/2000/svg"
              fill={"transparent"}
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke={primaryColor}
                strokeWidth="2"
              ></circle>
              <path
                className="opacity-75"
                fill={primaryColor}
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg> <span className="flex text-[17px] text-black">Patienter ...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailValidation;
