import { createContext } from "react";

export const SignInContext = createContext({
  isLoading: false,
  formData: {
    login: "",
    password: "",
  },
  onValueChange: (slug: string) => function (value: any) {},
  submitForm: function () {},
  errorState: {
    login: { state: false, msg: "" },
    password: { state: false, msg: "" },
    generalMsg: "",
  },
});
