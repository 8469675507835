import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { useAuthHook } from "../../contexts/auth/hooks";

const Home = () => {
  const { connected } = useAuthHook();
  const navigate = useNaviLoadBoth;

  React.useEffect(() => {
    if (connected) {
      navigate("/dashboard");
    } else {
      navigate("/signin");
    }
  }, [connected, navigate]);

  return <div className="flex flex-1" />;
};

export default Home;
