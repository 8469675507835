import { AxiosGetUrl } from "../../functions/axiosfunctions";

export function getCountries(cb = function (reponse: any) {}) {
  AxiosGetUrl("/country/all", (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}


export function getLangs(cb = function (reponse: any) {}) {
  AxiosGetUrl("/lang/all", (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}


export function getRoles(cb = function (reponse: any) {}) {
  AxiosGetUrl("/role/all", (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}
