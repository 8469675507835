import forge from "node-forge";

// Fonction pour déchiffrer des données encryptées avec une clé de chiffrement unique et un mode de chiffrement authentifié
function decryptDataWithKeyAndAuthMode(data: any, key: string) {
  // Décoder les données encodées en base64 et les paramètres de chiffrement
  const encrypted = forge.util.decode64(data.data);
  const iv = forge.util.decode64(data.iv);
  const tag = JSON.parse(forge.util.decode64(data.tag));

  // Créer un déchiffreur AES en mode GCM (chiffrement authentifié)
  const decipher = forge.cipher.createDecipher(
    "AES-GCM",
    forge.util.decode64(key)
  );

  // Configurer le déchiffreur avec le vecteur d'initialisation et le tag de vérification d'intégrité
  decipher.start({ iv, tag });

  // Décrypter les données
  decipher.update(forge.util.createBuffer(encrypted));
  const result = decipher.finish(); // renvoie 'true' si le déchiffrement a réussi

  // Vérifier que le déchiffrement a réussi (sinon, les données peuvent être corrompues ou la clé peut être incorrecte)
  if (!result) {
    throw new Error(
      "Failed to decrypt data. The data may be corrupt or the key may be incorrect."
    );
  }

  // Retourner les données décryptées
  return JSON.parse(decipher.output.toString());
}

export function decryptDataAesGcm(encryptionKey: any, data: any) {
  const result = decryptDataWithKeyAndAuthMode(
    { data, ...encryptionKey },
    encryptionKey.key
  );
  return result.data;
}
