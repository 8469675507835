import React from "react";

const ParamtreIcon = ({ color = "", ...args }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      {...args}
    >
      <path
        fillRule={"evenodd"}
        clipRule={color}
        d="M3.57751 0.288574C4.1829 0.288574 4.67366 0.779337 4.67366 1.38472V11.4372C5.95088 11.8886 6.86596 13.1067 6.86596 14.5385C6.86596 15.9703 5.95088 17.1884 4.67366 17.6398V18.9231C4.67366 19.5285 4.1829 20.0193 3.57751 20.0193C2.97212 20.0193 2.48136 19.5285 2.48136 18.9231V17.6398C1.20414 17.1884 0.289062 15.9703 0.289062 14.5385C0.289062 13.1067 1.20414 11.8886 2.48136 11.4372V1.38472C2.48136 0.779337 2.97212 0.288574 3.57751 0.288574ZM10.1544 0.288574C10.7598 0.288574 11.2506 0.779337 11.2506 1.38472V2.66799C12.5278 3.11943 13.4429 4.33751 13.4429 5.76932C13.4429 7.20113 12.5278 8.41921 11.2506 8.87065V18.9231C11.2506 19.5285 10.7598 20.0193 10.1544 20.0193C9.54902 20.0193 9.05825 19.5285 9.05825 18.9231V8.87065C7.78103 8.41921 6.86596 7.20113 6.86596 5.76932C6.86596 4.33751 7.78103 3.11943 9.05825 2.66799V1.38472C9.05825 0.779337 9.54902 0.288574 10.1544 0.288574ZM16.7313 0.288574C17.3367 0.288574 17.8274 0.779337 17.8274 1.38472V11.4372C19.1047 11.8886 20.0197 13.1067 20.0197 14.5385C20.0197 15.9703 19.1047 17.1884 17.8274 17.6398V18.9231C17.8274 19.5285 17.3367 20.0193 16.7313 20.0193C16.1259 20.0193 15.6351 19.5285 15.6351 18.9231V17.6398C14.3579 17.1884 13.4429 15.9703 13.4429 14.5385C13.4429 13.1067 14.3579 11.8886 15.6351 11.4372V1.38472C15.6351 0.779337 16.1259 0.288574 16.7313 0.288574ZM10.1544 4.67317C9.54902 4.67317 9.05825 5.16393 9.05825 5.76932C9.05825 6.37471 9.54902 6.86547 10.1544 6.86547C10.7598 6.86547 11.2506 6.37471 11.2506 5.76932C11.2506 5.16393 10.7598 4.67317 10.1544 4.67317ZM3.57751 13.4424C2.97212 13.4424 2.48136 13.9331 2.48136 14.5385C2.48136 15.1439 2.97212 15.6347 3.57751 15.6347C4.1829 15.6347 4.67366 15.1439 4.67366 14.5385C4.67366 13.9331 4.1829 13.4424 3.57751 13.4424ZM16.7313 13.4424C16.1259 13.4424 15.6351 13.9331 15.6351 14.5385C15.6351 15.1439 16.1259 15.6347 16.7313 15.6347C17.3367 15.6347 17.8274 15.1439 17.8274 14.5385C17.8274 13.9331 17.3367 13.4424 16.7313 13.4424Z"
        fill={color}
      />
    </svg>
  );
};

export default ParamtreIcon;
