import { AxiosGetUrl, AxiosPostUrl } from "../../functions/axiosfunctions";

export function getEmailStatus(id = "", cb = function (reponse: any) {}) {
  AxiosGetUrl("/client/auth/confirmationEmailStatus?id=" + id, (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}
export function verificateCode(
  data = { code: "", id: "" },
  cb = function (reponse: any) {}
) {
  AxiosPostUrl("/client/auth/confirmEmail", data, (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}
