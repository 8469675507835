import React from "react";
import ProjectCard from "./projectCard";
import { useAuthDataHook } from "../../../../contexts/authData/hooks";

const ProjectsArray = ({ search = "" }) => {
  const { projets } = useAuthDataHook();
  return (
    <div className="grid flex-row mb-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full gap-5 ">
      {projets
        .filter((p) =>
          search.trim().length === 0 ? true : p.projet_title.includes(search)
        )
        .map((project,index) => {
          return <ProjectCard key={project.projet_id+index.toString()} projet={project} />;
        })}
    </div>
  );
};

export default ProjectsArray;
