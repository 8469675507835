export const CountryObject = {
  country: "",
  code: "",
  capitals: Array<string>(),
  prefix: Array<string>(),
  lang: Array<string>(),
  villes: Array<{
    nom: "";
    code: "";
  }>(),
};

export const LangObject = {
  lang: "",
  slug: "",
  i18n: "",
  flag: "",
};

export const ClientObjet = {
  client_id: "",
  client_name: "",
  client_country: "",
};

export const AdminObject = {
  admin_id: "",
  admin_name: "",
  admin_head: "",
  admin_country: "",
  admin_role: "",
  admin_email: "",
  admin_password: "",
  admin_state: {
    actived: true,
    date: "",
    state: {},
  },
  admin_created_date: "",
};
export const ProjetObject = {
  projet_id: "",
  client_id: "",
  projet_title: "",
  projet_descripton: "",
  projet_created_date: "",
};
export const EtudeObject = {
  etude_id: "",
  client_id: "",
  projet_id: "",
  etude_title: "",
  etude_data: Array<{ name: ""; url: ""; public_id: ""; type: "" }>(),
  etude_descripton: "",
  etude_begin_date: "",
  etude_final_date: "",
  etude_created_date: "",
};
export const ClientObject = {
  client_id: "",
  client_name: "",
  client_logo: "",
  client_country: "",
  client_state: {
    actived: true,
    date: "",
    state: {},
  },
  client_created_date: "",
};

export const ReferantObject = {
  referant_id: "",
  referant_name: "",
  client_id: "",
  referant_head: "",
  referant_country: "",
  referant_email: "",
  referant_password: "",
  referant_created_date: "",
};

export const LogObject = {
  log_id: "",
  admin_id: "",
  action: "",
  created_date: "",
  description: "",
};
