import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordInput = ({
  onChange = (e: string) => {},
  errorState = { state: false, msg: "" },
  togglePassword = true,
  ...args
}) => {
  const [showPassword, setshowPassword] = React.useState(false);
  const handleClickShowPassword = () => setshowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <FormControl variant="outlined" color="warning" error={errorState.state}>
      {!!args.label && (
        <InputLabel
          htmlFor="outlined-adornment-password"
          error={errorState.state}
        >
          {args.label}
        </InputLabel>
      )}
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        error={errorState.state}
        className="flex bg-white border-none outline-none"
        onChange={function (value) {
          onChange(value.currentTarget.value);
        }}
        endAdornment={
          togglePassword && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }
        label={args.label}
      />
      {errorState.state && errorState.msg.length !== 0 && (
        <span className="flex w-full bg-white pl-[16px] py-1 font-medium text-red-600 text-[12px]">
          {errorState.msg}
        </span>
      )}
    </FormControl>
  );
};

export default PasswordInput;
