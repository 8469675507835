import { AxiosGetUrl } from "../../functions/axiosfunctions";

export function getProjets(id = "", cb = function (reponse: any) {}) {
  AxiosGetUrl("/client/projet/all?id=" + id, (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}

export function getEtudes(id = "", cb = function (reponse: any) {}) {
  AxiosGetUrl("/client/etude/all?id=" + id, (reponse) => {
    if (reponse) {
      cb(reponse);
    } else {
      cb({
        success: false,
        data: {},
        msg: "Erreur survenue !",
      });
    }
  });
}
