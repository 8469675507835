import React from "react";
import { ProjetContext } from "./context";
import { useAuthDataHook } from "../authData/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ClientObject, EtudeObject, ProjetObject } from "../../types/models";
import { LoadingCircle } from "../../pages/pageDataLoading";

const ProjetProvider = ({ children = <div /> }) => {
  const { client, projets, etudes } = useAuthDataHook();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = React.useState(true);
  const [_client, setClient] = React.useState(ClientObject);
  const [projet, setProjet] = React.useState(ProjetObject);
  const [etudess, setEtudess] = React.useState(Array<typeof EtudeObject>());
  React.useEffect(() => {
    const id = projectId;
    const projet = projets.filter((e) => e.projet_id === id)[0];
    if (id && projet) {
      const etudesss = etudes.filter((c) => c.projet_id === projet.projet_id);
      setProjet(projet);
      setClient(client);
      setEtudess(etudesss);
      setisLoading(false);
    } else {
      navigate("/dashboard/projets");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, etudes, projets, projectId]);

  return (
    <ProjetContext.Provider
      value={{ client: _client, projet, etudes: etudess }}
    >
      {isLoading ? <LoadingCircle /> : children}
    </ProjetContext.Provider>
  );
};

export default ProjetProvider;
