import { createContext } from "react";
import { ClientObject, ReferantObject } from "../../types/models";

export const AuthContext = createContext({
  connected: false,
  client: ClientObject,
  referant: ReferantObject,
  setConnectionData: (data: any) => {},
  logOut: () => {},
});
