import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./contexts/auth/provider";
import BasicDataProvider from "./contexts/basicdata/provider";
import AuthDataProvider from "./contexts/authData/provider";
import ModalsProvider from "./contexts/modals/provider";
import { I18nextProvider } from "react-i18next";
import I18next from "./configs/lang/index";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={I18next}>
        <BasicDataProvider>
          <AuthProvider>
            <ModalsProvider>
              <AuthDataProvider>
                <App />
              </AuthDataProvider>
            </ModalsProvider>
          </AuthProvider>
        </BasicDataProvider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
