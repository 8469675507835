import React from "react";
import Table from "./table";
import { useProjetHook } from "../../../../contexts/projet/hooks";
import { useBasicDataHook } from "../../../../contexts/basicdata/hooks";

const ProjetContent = () => {
  const { t } = useBasicDataHook();
  const { projet } = useProjetHook();
  return (
    <div className="flex flex-1 flex-col gap-5">
      <div className="flex flex-row gap-3 items-center">
        <span className="flex text-[15px] font-semibold">Projet :</span>
        <span className="flex text-[25px] font-semibold">
          {projet.projet_title}
        </span>
      </div>
      <div className="flex flex-row justify-between items-center">
        <span className="flex text-[22px] font-medium">
        {t("listStudies")}
        </span>
      </div>
      <Table />
    </div>
  );
};

export default ProjetContent;
