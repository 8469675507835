import forge from "node-forge";
import CryptoJS from "crypto-js";

export function generateSecureCode(length = 64) {
  if (typeof length !== "number" || length < 1) {
    throw new Error("La longueur doit être un nombre positif.");
  }
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-+=<>?{}[]|~";
  const charactersLength = characters.length;
  let secureCode = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    secureCode += characters[randomIndex];
  }
  return secureCode;
}

function localEntropie() {
  const persistentVar = "1z2a44b8op";
  let result = localStorage.getItem(persistentVar);
  if (result) {
    let data = CryptoJS.AES.decrypt(
      forge.util.decode64(result),
      (process.env.REACT_APP_LOCALDATA_KEY ?? "") + persistentVar
    ).toString(CryptoJS.enc.Utf8);

    var decryptedData = JSON.parse(data);
    return decryptedData.data;
  } else {
    const entr = generateSecureCode(36);
    const crypted = CryptoJS.AES.encrypt(
      JSON.stringify({ data: entr }),
      (process.env.REACT_APP_LOCALDATA_KEY ?? "") + persistentVar
    ).toString();
    localStorage.setItem(persistentVar, forge.util.encode64(crypted));
    return entr;
  }
}

export function generateUniqueName(nom = "") {
  if (typeof nom !== "string") {
    return "";
  }

  // Combine le nom et la valeur d'environnement
  const combinedString = nom + localEntropie();

  // Calcule le hachage SHA-512 de la chaîne combinée en utilisant node-forge
  const md = forge.md.sha512.create();
  md.update(combinedString);
  const hashDigest = md.digest().toHex();

  // Calcule la taille du nom généré en utilisant une somme de contrôle simple
  const checksum = nom.split("").reduce((sum, char) => {
    return sum + char.charCodeAt(0);
  }, 0);

  // Détermine la taille du nom généré (entre 4 et 12 caractères)
  const nameSize = 7 + (checksum % 9);

  // Prend les caractères du hachage en fonction de la taille déterminée
  const uniqueName = hashDigest.slice(0, nameSize);

  return uniqueName;
}
